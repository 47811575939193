@charset "utf-8";

// COLORS
$primary: #007aff;
$dark-primary: #005bbd;
$info: #209cee;
$success: #21ce99;
$warning: #ffdd57;
$danger: #ff3860;
$light: #f5f5f5;
$dark: #1f2023;

// TEXT
$text: inherit;
$link: #007aff;

$body-family: Inter, "Helvetica", "Apple Color Emoji", "Segoe UI Emoji",
  NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
  EmojiSymbols, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Helvetica Neue", "Noto Sans", sans-serif;

$title-family: false;
$button-family: false;

$title-color: #000000;
$subtitle-color: var(--text-lighter);
$text-strong: inherit;
$strong-color: inherit;
$title-weight: 500;
$body-line-height: 24px;
$title-weight: 500;
$box-background-color: #ffffff;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// CONTENT
$content-blockquote-background-color: inherit;
$content-blockquote-padding: 0.25em 1em;

// NAVBAR HEIGHTS
$navbar-item-img-max-height: 3rem;

@import "~bulma/bulma.sass";
