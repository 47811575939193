html,
body {
  margin: 0;
  padding: 0;
  font-family: Inter, 'Helvetica', 'Apple Color Emoji', 'Segoe UI Emoji',
    NotoColorEmoji, 'Noto Color Emoji', 'Segoe UI Symbol', 'Android Emoji',
    EmojiSymbols, -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Helvetica Neue', 'Noto Sans', sans-serif;
  scroll-behavior: smooth;
}

html {
  box-sizing: border-box;
}

body {
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

div::-webkit-scrollbar-thumb {
  background-color: var(--background-highlight-highlight);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

div::-webkit-scrollbar {
  width: 15px;
}



img.darken,
video.darken {
  filter: brightness(0.6);
}

:root {
  /* Your default theme */
  --background: #ffffff;
  --background-transparent: rgba(255, 255, 255, 0.5);
  --background-blur: rgba(255, 255, 255, 0.7);
  --background-highlight: #f0f3f9;
  --background-highlight-toolbar: #f0f3f9;
  --background-highlight-highlight: #e5eaf5;
  --background-toolbar: #ffffff;
  --background-highlight-transparent: rgba(255, 255, 255, 0.7);
  --background-button: #ffffff;
  --background-modal: linear-gradient(136.61deg,
      rgb(255, 255, 255) 13.72%,
      rgb(255, 255, 255) 74.3%);
  --background-modal-highlight: rgb(248, 249, 251);
  --background-popup-overlay: rgba(0, 0, 0, 0.7);
  --darker: rgba(45, 66, 102, 0.1);
  --shadow-modal-color: rgba(0, 0, 0, 0.09);
  --shadow-card: rgba(0, 0, 0, 0.12) 0px 10px 40px -10px;
  --shadow-card-highlight: rgba(0, 0, 0, 0.3) 0px 10px 40px -10px;
  --shadow-modal: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  --shadow-modal-highlight: rgba(35, 37, 59, 0.6) 0px 20px 60px -10px;
  --shadow-watermark: 3px 3px 10px 3px rgba(56, 28, 100, 0.1);
  --shadow-navbar: 0 0 20px rgba(0, 0, 0, 0.04);
  --shadow-button: 0px 4px 22px rgba(0, 0, 0, 0.1);
  --highlight: rgba(0, 0, 0, 0.05);
  --highlight-highlight: rgba(0, 0, 0, 0.1);
  --highlight-2: rgba(0, 0, 0, 0.15);
  --highlight-3: rgba(0, 0, 0, 0.3);
  --border-modal: rgb(239, 241, 244);
  --border-modal-transparent: rgb(239, 241, 244, 0.3);
  --border-highlight: rgb(231, 234, 238);
  /* --text: #3c4149; */
  --text: #000000;
  --side-background: #ffffff;
  --side-text: #3c4149;
  --text-sharp: #282a30;
  --text-light: #6b6f76;
  --text-toolbar: #6b6f76;
  --text-lighter: #929cad;
  --text-lighter2: rgb(191, 199, 214);
  --xraymode-border: #9393ff;
  /*
  --accent: #6e79d6;
  --accent-transparent: rgb(113, 124, 225, 0.2);
  --accent-semi-transparent: rgb(113, 124, 225, 0.5);
  --accent-highlight: rgb(113, 124, 225);
  */
  --accent: #007aff;
  --accent-text: #007aff;
  --accent-light: #efeffe;

  --root-accent: #007aff;
  --root-accent-light-blue: rgb(156, 232, 255);
  --root-accent-text: #007aff;
  --root-accent-light: #efeffe;
  --accent-transparent: rgba(0, 123, 255, 0.2);
  --accent-semi-transparent: rgba(0, 123, 255, 0.5);
  --accent-highlight: #77B8FF;
  --text-on-accent: #ffffff;

  /* input */
  --background-input: #ffffff;
  --border-input: rgb(223, 225, 228);
  --border-input-focused: rgb(33, 97, 220);
  --background-input-dark: #3c4149;

  /* inverts */
  --background-invert: #131415;
  --background-invert-highlight: #2d2f36;
  --text-invert: #d7d8db;
  --text-invert-light: #8a8f98;

  /* colors */
  --danger: rgb(255, 56, 96);
  --danger-soft: #ff839c;
  --danger-outline: rgb(255, 56, 96, 0.1);
  --danger-transparent: rgb(254, 236, 240, 0.3);
  --danger-background: rgb(254, 236, 240);
  --yellow: #f7cc5c;
  --green: rgb(72, 199, 116);
  --green-transparent: rgba(153, 255, 189, 0.3);
  --dark-green: #218d7f;

  /* dropline */
  --dropline: #5e9cee;
  --dropBackground: rgba(156, 232, 255, 0.45);
  --dropBackgroundLight: rgba(156, 232, 255, 0.08);

  /* global */
  --text-white-light: #6b6f76;
  --text-white-lighter: #929cad;
  --background-white-highlight: #f0f3f9;
  --background-white-highlight-highlight: #e5eaf5;
  --dark-transparent: rgb(39, 40, 43, 0.6);
  --light-transparent: rgb(215, 216, 219, 0.6);
  --background-input-dark: rgb(31, 32, 35);
  --border-input-dark: rgb(60, 63, 68);
  --border-input-focused-dark: rgb(100, 153, 255);
  --text-white: #d7d8db;
  --box-transition: opacity 0.3s,
    width 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --background-light-theme: #ffffff;
  --text-light-theme: #000000;
  --background-dark-theme: #131415;
  --text-dark-theme: #d7d8db;

  --color-unicorn: #f18099;
  --color-nebula: #b664b0;
  --color-water: #67b7e5;
  --color-candy: #9d8eee;
  --color-melon: #5cd4ac;
  --color-orange: #fa9e34;
  --color-yellow: #f7cc5c;
  --color-green: #23cfa4;
  --color-blue: #06c1ff;
  --color-indigo: #6968f7;
  --color-purple: #8a24ff;
  --color-pink: #f54b94;
  --color-red: #f32e60;
  --color-black: #000000;
  --color-white: rgba(0, 0, 0, 0.2);
  --color-accent: #007aff;

  --shadow-color-unicorn: var(--color-unicorn);
  --shadow-color-nebula: var(--color-nebula);
  --shadow-color-water: var(--color-water);
  --shadow-color-candy: var(--color-candy);
  --shadow-color-melon: var(--color-melon);
  --shadow-color-orange: var(--color-orange);
  --shadow-color-yellow: var(--color-yellow);
  --shadow-color-green: var(--color-green);
  --shadow-color-blue: var(--color-blue);
  --shadow-color-indigo: var(--color-indigo);
  --shadow-color-purple: var(--color-purple);
  --shadow-color-pink: var(--color-pink);
  --shadow-color-red: var(--color-red);
  --shadow-color-black: var(--color-black);
  --shadow-color-white: var(--color-white);
  --shadow-color-accent: var(--color-accent);

  --shadow-large: rgba(60, 66, 87, 0.08) 0px 15px 35px 0px,
    rgba(0, 0, 0, 0.12) 0px 5px 15px 0px;

  --navbar-item-img-max-height: 3.75rem;
  --card-border-radius: 1.25rem;
  --button-border-radius: 0.75rem;

  /* For react-toastify */
  --toastify-color-info: var(--accent);
  --toastify-color-success: var(--green);
  --toastify-color-warning: var(--yellow);
  --toastify-color-error: var(--danger);
}

[data-theme="dark"] {
  --background: #131415;
  --background-transparent: rgb(19, 20, 21, 0.5);
  --background-blur: rgba(19, 20, 21, 0.7);
  /* --background in RGB */
  --background-highlight: rgb(44, 44, 48);
  --background-highlight-toolbar: rgb(57, 57, 65);
  --background-toolbar: rgb(44, 44, 48);
  --background-highlight-transparent: rgba(66, 66, 66, 0.7);
  --background-highlight-highlight: rgb(57, 57, 65);
  /* --background-highlight-highlight: #32343d; */
  --background-button: #1f2124;
  /* --background-button: rgb(39, 40, 43); */
  --background-modal: linear-gradient(136.61deg,
      rgb(39, 40, 43) 13.72%,
      rgb(45, 46, 49) 74.3%);
  --darker: rgb(0, 0, 0);
  --background-modal-highlight: rgb(55, 55, 60);
  --background-popup-overlay: rgba(28, 29, 31, 0.5);
  --shadow-modal-color: rgba(0, 0, 0, 0.2);
  --shadow-modal: rgba(0, 0, 0, 0.35) 0px 7px 32px 0px;
  --shadow-modal-highlight: rgba(0, 0, 0, 1) 0px 20px 60px -10px;
  --shadow-card: rgba(0, 0, 0, 0.4) 0px 10px 40px -10px;
  --shadow-card-highlight: rgba(0, 0, 0, 0.8) 0px 10px 40px -10px;
  --shadow-watermark: 3px 3px 10px 3px rgba(0, 0, 0, 0.4);
  --shadow-navbar: 0 0 20px rgba(0, 0, 0, 0.4);
  --shadow-button: 0px 4px 22px rgba(0, 0, 0, 0.4);
  --highlight: rgba(255, 255, 255, 0.05);
  --highlight-highlight: rgba(255, 255, 255, 0.1);
  --highlight-2: rgba(255, 255, 255, 0.2);
  --highlight-3: rgba(255, 255, 255, 0.3);
  --border-modal: rgb(48, 50, 54);
  --border-highlight: rgb(50, 53, 59);
  --text: #ffffff;
  --side-background: #131415;
  --side-text: #d7d8db;
  --text-toolbar: #d7d8db;
  --text-light: #9a9fa8;
  --text-lighter: #8b8e9b;
  --text-lighter2: #494e5e;
  --text-sharp: #ffffff;
  --accent: #007aff;
  /* #5e6ad2; */
  --accent-text: #007aff;
  --accent-light: #1e3956;
  --accent-transparent: rgba(0, 123, 255, 0.2);
  /* #5e6ad2; */
  --accent-semi-transparent: rgba(0, 123, 255, 0.5);
  /* #5e6ad2; */
  --accent-highlight: #4da3ff;
  /*rgb(113, 124, 225);*/
  --text-on-accent: #ffffff;

  /* input */
  --background-input: rgb(31, 32, 35);
  --border-input: rgb(60, 63, 68);
  --border-input-focused: rgb(100, 153, 255);

  /* inverts */
  --background-invert: #ffffff;
  --background-invert-highlight: #f0f3f9;
  --text-invert: #3c4149;
  --text-invert-light: #6b6f76;

  /* dropline */
  --dropline: #b4d5ff;
  --dropBackground: rgba(156, 232, 255, 0.18);
  --dropBackgroundLight: rgba(156, 232, 255, 0.05);

  /* colors */
  --danger: rgb(255, 56, 96);
  --green-transparent: rgba(75, 129, 107, 0.3);
  --danger-transparent: rgb(204, 15, 53, 0.3);
  --danger-background: rgb(92, 32, 44);
  --shadow-color-unicorn: #000;
  --shadow-color-nebula: #000;
  --shadow-color-water: #000;
  --shadow-color-candy: #000;
  --shadow-color-melon: #000;
  --shadow-color-orange: #000;
  --shadow-color-yellow: #000;
  --shadow-color-green: #000;
  --shadow-color-blue: #000;
  --shadow-color-indigo: #000;
  --shadow-color-purple: #000;
  --shadow-color-pink: #000;
  --shadow-color-red: #000;
  --shadow-color-black: #000;
  --shadow-color-white: #000;
  --shadow-color-accent: #000;
}


.loader.is-loading.with-accent-border-color {
  border-bottom-color: var(--accent) !important;
  border-left-color: var(--background-highlight-highlight) !important;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper .loader-box,
.loader-box-big {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 30px;
}

.loader-wrapper .loader {
  height: 40px;
  width: 40px;
  border-bottom-color: var(--accent);
}

.loader-big {
  height: 40px !important;
  width: 40px !important;
  border-bottom-color: var(--accent);
}

.loader-small-wrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader-wrapper.is-active {
  opacity: 1;
  z-index: 5;
}

.is-loading {
  position: relative;
}


.with-background-color {
  background-color: var(--background);
}

.with-highlight-background-color {
  background-color: var(--background-highlight);
}

.with-accent-text-color {
  color: var(--accent);
}

.with-text-color {
  color: var(--text);
}

.with-light-text-color {
  color: var(--text-light);
}

.with-lighter-text-color {
  color: var(--text-lighter);
}

.is-size-1 {
  letter-spacing: -0.1rem;
}

.is-size-2 {
  letter-spacing: -0.1rem;
}

.is-size-3 {
  letter-spacing: -0.1rem;
}

.is-size-4 {
  letter-spacing: -0.035rem;
}

.is-size-5 {
  letter-spacing: -0.035rem;
}

.is-size-6 {
  letter-spacing: -0.035rem;
}

.is-size-6-5 {
  font-size: 0.85em;
  letter-spacing: -0.035rem;
}

.is-size-7 {
  letter-spacing: -0.035rem;
}

.is-title {
  color: var(--text);
  font-weight: 500;
}

.is-title-bold {
  color: var(--text);
  font-weight: 800;
}

.is-subtitle {
  color: var(--text-lighter);
  font-weight: 400;
}

svg {
  vertical-align: middle;
  margin-bottom: 2px;
}

.no-pointer-event {
  user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  -webkit-pointer-events: none;
  /* Chrome/Safari */
  -moz-pointer-events: none;
  /* Firefox */
  -ms-pointer-events: none;
  /* IE10+ */
}

.no-touch-action {
  user-select: none;
  touch-action: none;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  -webkit-touch-action: none;
  /* Chrome/Safari */
  -moz-touch-action: none;
  /* Firefox */
  -ms-touch-action: none;
  /* IE10+ */
}

.with-cursor-pointer {
  cursor: pointer;
}

.with-button-border-radius {
  border-radius: var(--button-border-radius) !important;
}

.with-card-border-radius {
  border-radius: var(--card-border-radius) !important;
}

.has-fullwidth {
  width: 100%;
}

.has-fit-content-width {
  width: fit-content !important;
}


.hr {
  background-color: var(--text-lighter) !important;
  height: 1px !important;
  opacity: 0.2;
  width: 100%;
}

.with-danger-text-color {
  color: var(--danger);
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}

/* Crop Image Styling */
.ReactCrop {
  overflow: visible !important;
}

.ReactCrop__drag-handle.ord-nw::after,
.ReactCrop__drag-handle.ord-ne::after,
.ReactCrop__drag-handle.ord-sw::after,
.ReactCrop__drag-handle.ord-se::after {
  width: 20px !important;
  height: 20px !important;
  background: none !important;
}

.ReactCrop__drag-handle.ord-nw {
  margin-top: -3px !important;
  margin-left: -3px !important;
}

.ReactCrop__drag-handle.ord-nw::after {
  border-right: none;
  border-bottom: none;
  border-top: 3px solid white;
  border-left: 3px solid white;
}

.ReactCrop__drag-handle.ord-ne {
  margin-top: -3px !important;
  margin-right: -3px !important;
}

.ReactCrop__drag-handle.ord-ne::after {
  border-left: none;
  border-bottom: none;
  border-top: 3px solid white;
  border-right: 3px solid white;
}

.ReactCrop__drag-handle.ord-se {
  margin-bottom: -3px !important;
  margin-right: -3px !important;
}

.ReactCrop__drag-handle.ord-se::after {
  border-left: none;
  border-top: none;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

.ReactCrop__drag-handle.ord-sw {
  margin-bottom: -3px !important;
  margin-left: -3px !important;
}

.ReactCrop__drag-handle.ord-sw::after {
  border-right: none;
  border-top: none;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
}
